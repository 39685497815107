import React, { Fragment, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import moment from "moment";
import { IconPlaneDeparture } from "@tabler/icons-react";
import LoaderCommon from "../../../Common/LoaderCommon";
import LeadStatus from "../../../Common/LeadStatus";
import PaginationComponent from "../../../Common/PaginationComponent";
import AdvancedSearch from "../../../Common/AdvancedSearch";
import ApplicantViewButton from "../../../Common/ApplicantViewButton";
import reportQueries from "../../../../queries/reportQueries";
import { useAdvancedSearch } from "../../../../store/advancedSearchStore";
import { usePaginationStore } from "../../../../store/userStore";
import FollowUp from "../../../Common/FollowUp";
import ApplicantEditButton from "../../../Common/ApplicantEditButton";
import { Pagination, PaginationItem } from '@mui/material'
import { Popover } from "antd";


const ApplicationManagementAndStatusReport =()=>{

    const [result, setResult] = useState([]);
    const [limit, setLimit] = useState(100);
    const [page, setPage] = useState(0);
    const [initial, setInitial] = useState(true);

    const data = useAdvancedSearch((state) => state);

    const getReport = reportQueries.useApplicationManagementAndStatusReport(
        (response) => {
            setInitial(false);
            setResult(response);   
        }
    );

    useEffect(()=>{
        getReport.mutateAsync({
            ...data,
            financialYear: initial ? "CURRENT" : data?.financialYear,
        });
    },[data]);

    const counselorName =(counselor)=>{
        if(!counselor){
            return 'N/A'
        }
        return `${counselor?.firstname} ${counselor?.lastname}`
    }

    const findHeading =()=>{
        const awaiting = data?.applicationStatus?.includes(3)
        const conditional = data?.applicationStatus?.includes(5)
        const unconditional = data?.applicationStatus?.includes(6)
        if(awaiting && !conditional && !unconditional){
            return 'AWATING'
        }else if(!awaiting && (conditional || unconditional)){
            return 'DUE'
        }else{
            return 'BOTH'
        }
    }

    const heading = findHeading()

    return(
        <div>
            <div className="breadcrumb-and-otherbuttons">
                <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to="/dashboard">Home</Link></li>
                    <li className="breadcrumb-item active" aria-current="page">Application Management</li>
                </ol>
                </nav>
            </div>
            
            <div className="add_clients">
                <div className="flex items-center gap-[4px] mb-4">
                    <i className="followup-icon">
                        <IconPlaneDeparture size="25" className='text-[#7D6FAB]' />
                    </i>
                    <div style={{display:'flex', flexDirection:'column'}}>
                        <div style={{display:'flex', alignItems:'center', gap:'10px'}}>
                            <span className="text-[16px] font-medium">Application Management</span>	
                        </div>
                    </div>
                </div>
                <div id="accordion" className="add_clients_accordion_form mt-[10px]">
                    <div  className="card" id="appointments_wrpr_employee">
                        <div className="card-header">
                            Application Management
                        </div>
                        <div id="appointment_any" className="collapse show" data-parent="#accordion">
                            <div className="card-body">

                            <AdvancedSearch appmanagementreport />

                            <div className="search-results-count-wrpr">
                            {
                                result?.data?.length > 0 
                                ? `Search Results ${page * limit + 1}-${Math.min((page + 1) * limit, result?.data?.length)} of ${result?.data?.length}` 
                                : `0 results found`
                            }
                            </div>

                            <div className="flex flex-col gap-2 mb-4">
                                <span className="custom-color text-white text-center font-medium text-[12px] px-2 py-2 rounded-[5px] w-[290px]">Total number of applications: {result?.data?.length}</span>
                                <span className="custom-color text-white text-center font-medium text-[12px] px-2 py-2 rounded-[5px] w-[290px]">Total number of unique applicants: {result?.totalApplicants}</span>
                            </div>

                            {getReport.isLoading ?
                                <LoaderCommon />:
                                <div className="table-wrpr">
                                    <div className="table-section">
                                        <div className="row">
                                            <div className="col-md-12">                                   
                                                <table border={1} className="table prospect-table">
                                                    <thead>
                                                        <tr className="!font-bold">
                                                            <th width="30">#</th>
                                                            <th>Profile Status</th>
                                                            <th>Office</th>
                                                            <th>Applicant Name</th>
                                                            <th>Contact Number</th>
                                                            <th>Visa Type</th>
                                                            <th>College/University</th>
                                                            <th>Intake</th>
                                                            <th>Counselor</th>
                                                            <th>Prospect Status</th>
                                                            <th>Application Status</th>
                                                            <th>
                                                                {
                                                                    heading === "AWATING" ? 
                                                                    "App. submitted on" :
                                                                    heading === "DUE" ?
                                                                    "Offer due date" :
                                                                    "App. submitted on/ Offer due date"
                                                                }
                                                            </th>
                                                            <th>No of days</th>
                                                            <th></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody  className="ui-sortable">
                                                        {   
                                                            result?.data?.slice( page * limit , limit * (page + 1))?.map((item, index) =>{
                                                            const application = item?.application
                                                            return(
                                                                <tr className="ui-sortable-handle">
                                                                    <td>{page * limit + index + 1}</td>
                                                                    <td><LeadStatus item={application}/></td>
                                                                    <td>{application?.office?.name}</td>
                                                                    <td>{application?.applicant_name} {application?.middlename} {application?.lastname}</td>
                                                                    <td>
                                                                        <div className="email-mobile flex flex-col">
                                                                            <div>{application?.mobile_no}</div>
                                                                            <div>{application?.landline_no}</div>
                                                                            <div>{application?.whatsapp_no}</div>
                                                                        </div>
                                                                    </td>
                                                                    <td>{application?.visatype?.description} </td>
                                                                    <td>{item?.college?.name}</td>
                                                                    <td>
                                                                        <Popover
                                                                        placement="top"
                                                                        content={
                                                                            <div>
                                                                                {
                                                                                    application?.intake_histories?.map((element, intakeIndex) => (
                                                                                        <div key={intakeIndex}>
                                                                                            <span className="font-semibold">{element?.intake?.intake}</span> - {moment(element?.createdAt).format("DD-MM-YYYY")}
                                                                                        </div>
                                                                                    ))
                                                                                }
                                                                            </div>
                                                                        }
                                                                        title="Intake History"
                                                                        trigger="hover"
                                                                        >
                                                                        {application?.intake?.intake}
                                                                        </Popover>
                                                                    </td>
                                                                    <td>{counselorName(application?.assignUser?.CounselorAssign?.userdetail)}</td>
                                                                    <td>{application?.status_of_prospect?.name}</td>
                                                                    <td>{item?.appstaffstatuss?.name}</td>
                                                                    <td>
                                                                        {
                                                                            item?.sid === 3 ?
                                                                            item?.statusAt ? moment(item?.statusAt).format("DD-MM-YYYY") : "-" :
                                                                            item?.due_date ? moment(item?.due_date).format("DD-MM-YYYY") : "-"
                                                                        }
                                                                    </td>
                                                                    <td className="text-[12px]">
                                                                        {
                                                                            item?.sid === 3 && item?.statusAt &&
                                                                            <span className={`${(moment(new Date()).diff(item?.statusAt, 'days') > 179 && application?.visatype?.country_id === 7) && 'bg-red-500 text-white px-2 py-1 rounded-[5px] inline-block'} `}>
                                                                                {moment(new Date()).diff(item?.statusAt, 'days')} Days delay
                                                                            </span>
                                                                        }
                                                                        {
                                                                            (item?.sid === 5 || item?.sid === 6) && item?.due_date &&
                                                                            <span className={`${moment(new Date()).diff(item?.due_date, 'days') > 0 && 'bg-orange-500 text-white px-2 py-1 rounded-[5px] inline-block'} `}>
                                                                                {
                                                                                    moment(item?.due_date).diff(new Date(), 'days') < 0 ?
                                                                                    <Fragment>
                                                                                        {moment(new Date()).diff(item?.due_date, 'days')} Days passed
                                                                                    </Fragment>:
                                                                                    <Fragment>
                                                                                        {moment(item?.due_date).diff(new Date(), 'days')} Days remaining
                                                                                    </Fragment>
                                                                                } 
                                                                            </span>
                                                                        }
                                                                    </td>
                                                                    <td className="flex flex-col gap-1">
                                                                        <div className="w-[120px]">
                                                                            <FollowUp item={application} />
                                                                        </div>
                                                                        <div className="w-[120px] flex gap-1">
                                                                            <ApplicantViewButton item={application} />
                                                                            <ApplicantEditButton item={application} />
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            )}
                                                        )}
                                                    </tbody>
                                                </table>
                                                <ul className="pagination pagination-sm justify-content-center table-pagination mt-4 mb-3 custom-pagination">
                                                    <Pagination
                                                        count={Math.ceil(result?.data?.length / limit)}
                                                        nextText="Next"
                                                        prevText="Previous"
                                                        renderItem={
                                                            (item) => (
                                                                <div>
                                                                <PaginationItem
                                                                component="li"
                                                                {...item}
                                                                className={`!bg-white !text-[12px] ${item.selected && '!border-rose-500'}`}
                                                                />
                                                                </div>
                                                            )
                                                        }
                                                        variant="outlined"
                                                        page={page + 1}
                                                        shape="rounded"                
                                                        onChange={(e, value)=> setPage(value - 1)}
                                                    />
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                            
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default ApplicationManagementAndStatusReport;


